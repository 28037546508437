import Api from '@/service/Api'


const USER_API_URL = "/backend";


class SocialService 
{

  get() {
    return Api().get(`${USER_API_URL}/social/get.php`)
  }
  
}

export default new SocialService();
