<template>
  <div class="contact contactform py-lg-5 py-0 pb-lg-3 pb-0">
    <h1 class="text-black text-fw-bold text-center pb-4">
      {{ TituloContactano }}
    </h1>
    <div class="contact-container py-3">
      <div class="contact-form bg-white my-lg-5 my-0 col-md-11 col-12 mx-auto p-lg-5 p-0">
        <div class="col-md-9 col-10 mx-auto my-3 d-flex flex-wrap justify-content-between">
          <FormKit :config="{
            classes: {
              input: 'fw-medium text-black p-4 border-0',
            },
          }" type="form" id="newsletter" :actions="false" @submit="submitToMailchimp">
            <FormKit outer-class="col-lg-3 col-12" type="text" name="name" validation="required" v-model="user.name"
              :placeholder="ArrayContact[0]" />
            <FormKit outer-class="col-lg-3 col-12" type="text" name="last name" validation="required"
              v-model="user.lastname" :placeholder="ArrayContact[1]" />
            <FormKit outer-class="col-lg-3 col-12" type="email" name="email" validation="required|email"
              v-model="user.email" :placeholder="ArrayContact[2]" />

            <FormKit outer-class="col-12" v-model="user.message" type="textarea" rows="10" name="message"
              validation="required" :placeholder="ArrayContact[3]" />
          </FormKit>
        </div>
        <div class="w-100 text-center pt-2 pb-lg-0 pb-5">
          <button @click.prevent="submitForm" class="btn border-1 border-dark py-2 px-lg-4 px-5 mt-3 fw-regular">
            {{ ArrayContact[4] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactoForm",
  data() {
    return {
      user: {
        name: "",
        lastname: "",
        email: "",
        message: "",
      },
    };
  },
  props: {
    TituloContactano: {
      type: String,
    },
    ArrayContact: {
      type: Array,
    },
  },
  methods: {
    submitForm() {
      this.$formkit.submit("newsletter");
    },
    submitToMailchimp() {
      alert("Email Sent Successfully!.");
      this.$formkit.reset("newsletter");
    },
  },
};
</script>

<style lang="css">
.contact-container {
  background-color: #d5d5d5;
}

.contact-form {
  border: 2px solid #ececec;
}

.contact-form .formkit-input {
  background-color: #ececec !important;
  border-radius: 17px !important;
  font-size: 15px;
  font-family: "Montserrat" !important;
  font-weight: 100 !important;
}

.contact-form textarea {
  height: 300px;
}

.contact-form .formkit-inner {
  box-shadow: none !important;
}

.contact-form .formkit-wrapper {
  max-width: 100% !important;
}

.contact-form #newsletter {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-form ::placeholder {
  color: #222222;
  font-weight: bold;
}

@media (max-width: 992px) {

  .contact-form textarea,
  .contact-form .formkit-input {
    font-size: 18px;
  }

  .contact-container {
    background-color: transparent;
  }

  .contact .btn {
    font-size: 18px;
  }
}
</style>
