<template>
  <div
    class="menu-cabecera d-flex flex-lg-row flex-column px-lg-5 px-3 py-2"
    :style="BgResponsive"
  >
    <nav
      :class="[
        'col-lg-9',
        'col-12',
        'm-0',
        'px-lg-0',
        'px-md-5',
        'px-sm-3',
        'px-0',
        'navbar',
        'navbar-expand-lg',
      ]"
    >
      <div class="container-fluid m-0 p-0 col-12">
        <!--LOGO-->
        <a
          id="Brand"
          class="navbar-brand brand-azul col-lg-4 col-md-6 col-8 me-2"
          href="/"
          :style="LogoResponsive"
        >
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          ref="BtnHamburguesa"
          @click="myFilter()"
          :class="{ active: isActive }"
        >
          <span
            id="Menu-icon"
            class="navbar-toggler-icon"
            :style="IconResponsive"
          ></span>
        </button>
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
          <!--Items Menu-->
          <ul
            class="navbar-nav me-auto mb-2 mb-lg-0 align-items-center justify-content-around col-12 mt-lg-0 mt-5"
          >
            <li class="nav-item" v-for="menu in menus" :key="menu">
              <div v-if="menu.submenu == 5">
                <router-link :to="menu.rout + 'es'" @click="close()">
                  {{ menu.nameSpanish }}
                </router-link>
              </div>
              <div v-if="menu.submenu == 0">
                <router-link :to="menu.rout + idioma" @click="close()">
                  {{ menu.nameSpanish }}
                </router-link>
              </div>
              <!-- opcion servicios -->
              <div
                id="MenuPrin"
                class="nav-item dropdown"
                v-if="menu.submenu == 1"
              >
                <div
                  @click="MenuDesplegable"
                  id="navbarDropdown"
                  ref="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :collapse="false"
                  :to="menu.rout + idioma"
                  :style="Cambio"
                  :class="[
                    { activeServicie: subIsActive('/service') },
                    'inactive',
                  ]"
                >
                  {{ menu.nameSpanish }}
                  <font-awesome-icon icon="angle-down" />
                </div>
                <ul
                  :class="MenuServices"
                  class="dropdown-menu text-center submenu-1"
                  aria-labelledby="navbarDropdown"
                  ref="MenuPrinDisplay"
                >
                  <li
                    v-for="menusServicio in menusServicios"
                    :key="menusServicio"
                  >
                    <!-- sub menu servicios -->
                    <div v-if="menusServicio.parent_id == menu.id">
                      <div v-if="menusServicio.submenu == 0">
                        <router-link
                          class="dropdown-item"
                          :to="menusServicio.rout + idioma"
                          @click="close()"
                        >
                          {{ menusServicio.nameSpanish }}
                        </router-link>
                        <hr class="dropdown-divider" />
                      </div>
                      <div v-else>
                        <!-- Graphic -->
                        <li
                          class="d-flex flex-wrap multilevelDropdownMenu2 dropdown dropright"
                        >
                          <router-link
                            class="dropdown-item w-75 text-lg-center text-end"
                            :to="menusServicio.rout + idioma"
                            @click="close()"
                          >
                            {{ menusServicio.nameSpanish }}
                          </router-link>
                          <a
                            class="dropdown-item MenuDesplegable dropdown-toggle text-start w-25"
                            href="#"
                            id="multilevelDropdownMenu2"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ref="navbarDropdown2"
                          >
                            <font-awesome-icon icon="angle-down" />
                          </a>
                          <ul
                            class="dropdown-menu text-center mx-auto"
                            aria-labelledby="multilevelDropdownMenu2"
                          >
                            <li
                              v-for="menuGraphic in menusServicios"
                              :key="menuGraphic"
                            >
                              <div
                                v-if="menuGraphic.parent_id == menusServicio.id"
                              >
                                <router-link
                                  :to="menuGraphic.rout + idioma"
                                  @click="close()"
                                  class="dropdown-item"
                                >
                                  {{ menuGraphic.nameSpanish }}
                                </router-link>
                                <hr class="dropdown-divider d-lg-flex d-none" />
                              </div>
                            </li>
                          </ul>
                        </li>
                        <hr class="dropdown-divider" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="borderFranja d-lg-block d-none"></li>
          </ul>
        </div>
      </div>
    </nav>




    <div
      class="responsive-float align-items-center d-flex flex-lg-row flex-column"
    >
      <div
        class="ms-1 idiomas d-flex flex-lg-row flex-column align-items-center order-lg-1 order-3"
        id="menues-float-icon1"
      >
        <div class="nav-item me-0 mb-lg-0 mb-3 float btnIdioma">
          <a @click="close()" href="#" class="nav-link p-0">
            <img class="w-75" src="@/assets/src/img/icon/spain.svg" />
          </a>
        </div>
        <div class="nav-item me-0 mb-lg-0 mb-3 float">
          <router-link to="/" @click="close()" class="nav-link p-0">
            <img
              class="w-75"
              src="@/assets/src/img/icon/unitedstatesofamerica.svg"
            />
          </router-link>
        </div>
      </div>
      <div
        :class="[
          'btn-contact',
          'p-3',
          'mb-2',
          'ms-0',
          'float',
          'order-2',
          'd-lg-none',
          'd-flex',
        ]"
        id="menues-float-icon2"
      >
        <a
          class="btn d-flex btn-contact align-items-center py-lg-2 px-lg-4 p-0 nav-link"
          href="#"
        >
          <div class="bg-redes-btn"></div>
        </a>
      </div>
      <div
        :class="[
          'btn-contact',
          'align-items-center',
          'nav-item',
          'p-lg-0',
          'p-3',
          'mb-lg-0',
          'mb-2',
          'ms-lg-4',
          'ms-0',
          'float',
          'order-lg-2',
          'order-1',
          'mb-lg-0',
          { active: show },
        ]"
        id="menues-float-icon3"
      >
        <a
          class="btn d-flex align-items-center justify-content-center py-lg-2 px-lg-1 py-0 px-0 nav-link p-3 mb-lg-0 mb-1 ms-0"
          href="#"
          @click="show = !show"
        >
          <div class="bg-contact-btn"></div>
          <div class="text-end m-0 p-0 d-lg-flex d-none">
            <p class="fw-regular m-0 nav-link w-100 p-0">
              {{ BtnContactText }}
            </p>
          </div>
        </a>
        
      </div>


      <div v-if="show" class="dropdown-menu show border-0 bg-white">
        <form class="p-0 border-0">
          <div class="dropdown-contact-content bg-white py-md-4 py-4">
            <div class="d-lg-none d-flex w-100 justify-content-end">
              <button
                type="button"
                class="btn-close px-4 fs-5"
                aria-label="Close"
                @click="show = false"
              ></button>
            </div>

            <div class="dropdown-contact-header border-0 text-center py-3">
              <img
                src="@/assets/src/img/webp/LogoPSOFNTECHORIGINAL.webp"
                class="col-lg-6 col-7 mx-auto pb-3"
              />
            </div>
            <div class="dropdown-contact-body col-11 mx-auto">
              <p class="text-black text-center fw-regular mb-4">
                {{ ArrayFormContact[0] }}
              </p>
              <div class="dropdown-contact-form">
                <p class="text-black fw-medium mb-1 ms-2">
                  {{ ArrayFormContact[1] }}
                </p>
                <input
                  class="rounded-3 col-12 col-12 fw-medium text-black p-lg-2 p-md-4 p-3"
                />
                <p class="text-black fw-medium mb-1 ms-2 mt-4">
                  {{ ArrayFormContact[2] }}
                </p>
                <input
                  class="rounded-3 col-12 fw-medium text-black p-lg-2 p-md-4 p-3"
                />
                <p class="text-black fw-medium mb-1 ms-2 mt-4">
                  {{ ArrayFormContact[3] }}
                </p>
                <textarea
                  class="rounded-3 col-12 fw-medium text-black p-lg-2 p-md-4 p-3"
                ></textarea>
              </div>
            </div>
            <div class="dropdown-contact-footer text-center py-2">
              <button
                type="button"
                class="btn text-white fw-semibold mx-auto"
              >
                {{ ArrayFormContact[4] }}
              </button>
            </div>
          </div>
        </form>
      </div>

    </div>
    <div class="contact-sombra" v-if="show"></div>



    <!-- menu botton -->
    <div id="menu-bottom">
      
      <div class="row">

        <div class="col-3">
          <a href="#" @click.prevent="show = !show">
            <img src="@/assets/src/img/icon/ContactUsBlanco.svg">
          </a>
        </div>

        <div class="col-3">
          <router-link to="/" @click="close()">
            <img src="@/assets/src/img/icon/unitedstatesofamerica.svg">
          </router-link>
        </div>

        <div class="col-3">
          <a href="#">
            <img src="@/assets/src/img/png/redesBlanco.png">
          </a>
        </div>

        <div class="col-3">
          <a href="#">
            <i class="fa-solid fa-arrow-up-from-bracket text-white" id="mb-arrow-up"></i>
          </a>
        </div>

      </div>

    </div>
    <!--/ menu botton -->



  </div>
  <router-view />
</template>



<style scoped>


  /* menu bottom */

  #menu-bottom {
    display: none;
  }

  @media(max-width: 991px) {

    #menues-float-icon1,
    #menues-float-icon2,
    #menues-float-icon3 {
      display: none !important;
    }

    #menu-bottom {
      display: block;
      position: fixed;
      width: 108%;
      bottom: 0px;
      left: -20px;
      background: #6466cb;
      z-index: 2;
      box-shadow: 0px -1px 6px 1px #444444;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }

    #menu-bottom img {
      width: 35px;
    }

    #mb-arrow-up {
      font-size: 35px;
    }

  }

  @media(max-width: 375px) {

    #menu-bottom img {
      width: 30px;
    }

    #mb-arrow-up {
      font-size: 30px;
    }

  }

  @media(max-width: 320px) {

    #menu-bottom {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    #menu-bottom img:nth-child(1n) {
      margin-left: 25px;
    }

    #menu-bottom img {
      width: 27px;
    }

    #mb-arrow-up {
      font-size: 27px;
    }

  }
  
  /*/ menu bottom */
  



.multilevelDropdownMenu2 .dropdown-menu {
  left: 100% !important;
  top: -288% !important;
}
.multilevelDropdownMenu2 .dropdown-menu .dropdown-item {
  color: #6466cb !important;
}
.multilevelDropdownMenu2 .show svg {
  transition: 0.5s;
  transform: rotate(-90deg) !important;
}

#MenuPrin .dropdown-menu {
  top: 50px;
  left: -80px;
  margin-top: 0.125rem;
}
nav #MenuPrin li .router-link-exact-active {
  border-bottom: none;
}
/*Barra Gris Menu */
.borderFranja {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 100px;
}
/*Btn-Contact y redes*/
.bg-contact-btn,
.bg-redes-btn,
.btn-contact.active {
  background-repeat: no-repeat;
  background-size: contain;
}
.bg-redes-btn {
  background-image: url(@/assets/src/img/png/redesBlanco.png);
  width: 25px;
  height: 25px;
}
.bg-contact-btn {
  background-image: url(@/assets/src/img/icon/ContactUsBlanco.svg);
  width: 50px;
  height: 35px;
}
.btn-contact:hover .bg-contact-btn,
.btn-contact.active .bg-contact-btn {
  background-image: url(@/assets/src/img/icon/ContactUsIconazul.svg);
}
.btn-contact {
  background: #001d7e;
  border-radius: 16px;
  z-index: 12;
  transition: 0.5s;
}

.btn-contact .nav-link {
  color: white !important;
}
.btn-contact:hover,
.btn-contact.active {
  background: white;
}
.btn-contact:hover .nav-link,
.btn-contact.active .nav-link {
  color: #001d7e !important;
}
.btn-contact .dropdown-toggle::after {
  display: none;
}
/* FORM CONTACT */

.dropdown-contact-body p {
  font-size: 13px;
}
.dropdown-contact-form input,
.dropdown-contact-form textarea {
  border: 1px solid #d1d1d1;
}
.dropdown-contact-form textarea {
  height: 100px;
}
.dropdown-contact-footer .btn {
  background-color: #061f42;
  font-size: 13px;
  transition: 0.5s;
}
.dropdown-contact-footer .btn:hover {
  background-color: #6466cb !important;
  color: #001d7e !important;
}
.dropdown-contact-content {
  box-shadow: 8px 8px 13px #00000021;
  border-radius: 1rem;
}
.contact-sombra {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
}
/*Menu flotante*/
.responsive-float .dropdown-menu {
  position: absolute;
  width: 400px;
  right: 3rem;
  top: 120px;
  border-radius: 18px;
}
/*MENU*/
.menu-cabecera {
  height: 150px;
  box-shadow: 0px 3px 6px #00000029;
}

.menu-cabecera .navbar-brand {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
}
/* lINK */
nav a,
nav .inactive {
  font-weight: 600;
  color: #222222;
  text-decoration: none;
  cursor: pointer;
}

nav .router-link-exact-active {
  color: #001d7e;
  font-weight: 600;
  border-bottom: 3px solid #001d7e;
}
#MenuPrin .dropdown-toggle::after {
  display: none;
}
#MenuPrin .show svg {
  transition: 0.5s;
  transform: rotate(180deg);
}
/* DRPDOWN */
.menu-cabecera .dropdown-menu .dropdown-item {
  color: #001d7e;
  font-weight: 500;
  padding: 15px;
  font-size: 14px;
}
/*lOGO */
.brand-azul {
  background-image: url(@/assets/src/img/webp/LogoPSOFNTECHORIGINAL.webp);
}
@media (max-width: 1250px) {
  nav a,
  nav .inactive,
  .btn-contact .nav-link {
    font-size: 9px;
  }
  .bg-contact-btn {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 992px) {
  .btnIdioma {
    display: none;
  }
  /* Boton Contact*/
  .bg-contact-btn {
    width: 30px;
    height: 30px;
  }
  .btn-contact {
    border-radius: 50%;
  }
  /*Float Menu*/
  .responsive-float {
    position: fixed;
    width: 70px;
    height: auto;
    bottom: 0px;
    left: 0px;
    z-index: 11;
    right: 0;
  }
  .responsive-float .dropdown-menu {
    position: fixed;
    left: 0;
    top: 10px;
    width: 70%;
    z-index: 13;
    margin: 0% 25%;
  }
  .menu-cabecera .responsive-float .nav-link {
    padding: 0 !important;
  }
  /*Menu*/
  .menu-cabecera {
    height: 100%;
  }
  .nav-item {
    width: 100%;
    text-align: center;
  }
  .multilevelDropdownMenu2 .w-75 {
    width: 70% !important;
  }
  .menu-cabecera .navbar-toggler {
    border: none;
    font-size: 2.5rem;
  }

  .menu-cabecera .navbar-toggler:focus {
    box-shadow: none;
  }

  .menu-cabecera .navbar-toggler-icon {
    width: 1.1em !important;
    background-image: url(@/assets/src/img/icon/menuICON.svg) !important;
  }

  .menu-cabecera .idiomas .w-75 {
    width: 50px !important;
  }
  /*Links*/
  nav a,
  nav .inactive {
    color: white !important;
    font-size: 20px;
  }
  nav li {
    padding-bottom: 50px !important;
  }

  nav .router-link-exact-active {
    color: white;
    border-bottom: none;
  }

  /*Dropdown*/
  .dropdown {
    width: 100%;
    text-align: center;
  }

  .menu-cabecera .dropdown-menu .dropdown-item {
    color: white !important;
    font-weight: 400;
    padding: 15px;
    font-size: 20px;
  }

  nav .dropdown-menu li {
    padding-bottom: 0px !important;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: transparent;
  }

  .dropdown-divider {
    border-top: 2px solid white;
  }

  .rounded-3 {
    border-radius: 0.8rem !important;
  }
  .navbar-nav .dropdown-menu {
    border: 0;
    background: transparent;
  }
}
@media (max-width: 600px) {
  .menu-cabecera .navbar-toggler-icon {
    width: 1em !important;
  }
  .responsive-float .dropdown-menu {
    width: 90%;
    margin: 0% 5%;
  }
  .menu-cabecera .dropdown-menu .dropdown-item,
  nav a,
  nav .inactive {
    font-size: 18px;
  }
  .menu-cabecera .dropdown-menu .dropdown-item {
    font-size: 16px;
  }
}
</style>



<script>
import DataView from "@/service/DataView";

export default {
  nameSpanish: "MenuCabecera",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      BtnContactText: "CONTÁCTANOS",
      ArrayFormContact: [
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.",
        "Nombre",
        "Correo",
        "Mensaje",
        "Enviar",
      ],
      show: false,
      isActive: false,
      BgResponsive: {
        backgroundColor: "white",
      },
      Cambio: {
        color: "",
        borderBottom: "",
      },
      LogoResponsive: {
        filter: "none",
      },
      IconResponsive: {
        filter: "none",
      },
      menu: [],
      menus: [],
      menusServicio: [],
      menusServicios: [],
      MenuServices: "",
      idioma: "/es",
    };
  },

  created() {
    this.mostrar();
    this.mostrarServicios();
  },
  methods: {
    MenuDesplegable() {
      if (this.MenuServices == "d-block") {
        this.MenuServices = "";
      } else {
        this.MenuServices = "d-block";
      }
    },

    async mostrar() 
    {
      let es = this;
      let response = await DataView.selectMenu({
        opcion: 1,
        idTable: "id_pages",
        id: 0,
      })

      es.menus = response.data;
    },

    async mostrarServicios() 
    {
      let es = this;
      let response = await DataView.selectMenu({
        opcion: 2,
        id: 0,
      })
      
      es.menusServicios = response.data;
    },

    
    myFilter: function () {
      this.isActive = !this.isActive;
      let hola = this.isActive;
      if (hola == true) {
        this.$data.BgResponsive.backgroundColor = "#001d7e";
        this.$data.LogoResponsive.filter =
          "invert(1) hue-rotate(100deg) saturate(0%)";
        this.$data.IconResponsive.filter =
          "invert(1) hue-rotate(100deg) saturate(0%)";
      } else {
        this.$data.BgResponsive.backgroundColor = "white";
        this.$data.LogoResponsive.filter = "none";
        this.$data.IconResponsive.filter = "none";
      }
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        if (this.$route.path.indexOf(path) == 0) {
          this.$data.Cambio.color = "#001d7e";
          this.$data.Cambio.borderBottom = "3px solid #001d7e";
        } else {
          this.$data.Cambio.color = "#222222";
          this.$data.Cambio.borderBottom = "none";
        }
      });
    },
    close() {
      this.MenuServices = "";
      // dropdowns.style.display = "none";
      if (window.innerWidth <= 992) {
        var objO = this.$refs.BtnHamburguesa;
        objO.click();
      }
    },
  },
};
</script>
