<template>
  <div id="Banner" class="banner p-0 m-0">
    <div
      class="align-items-center d-flex flex-lg-nowrap flex-wrap py-lg-5 py-0 col-lg-11 col-12 mx-auto justify-content-lg-between justify-content-center"
    >
      <div
        class="col-md-12 col-sm-10 col-12 px-lg-0 px-md-5 px-3 order-lg-1 order-2 my-lg-0 my-4 banner-service-items d-lg-block d-flex flex-wrap"
      >
        <div
          v-for="(service, x) in ArrayBannerIndex"
          :key="service.id"
          :class="[arrayClassFloat[x], 'col-lg-6', 'col-4']"
        >
          <div
            class="col-lg-4 col-md-9 col-10 mx-lg-0 mx-auto servicio-item p-lg-0 p-md-3 p-1 mt-lg-4 mt-0"
          >
            <router-link
              :to="HrefBanner[x] + idioma"
              class="row col-12 m-0 p-0"
            >
              <div class="icon text-center col-lg-4 col-12 m-0 p-0 my-auto">
                <img
                  class="h-auto w"
                  :src="publicPath + 'img/' + IconBannerIndex[x]"
                  alt=""
                />
              </div>
              <div
                class="col-lg-8 col-12 d-lg-flex text-lg-start text-center align-items-center p-0"
              >
                <p
                  class="ps-lg-3 ps-0 pt-lg-0 pt-4 m-0 mx-lg-0 mx-md-4 mx-0"
                  v-html="ArrayBannerIndex[x]"
                ></p>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div
        class="banner-swiper d-flex align-items-center col-lg-11 col-12 order-lg-2 order-1 justify-content-center"
      >
        <swiper v-if="banners.length > 0"
          :spaceBetween="30"
          :centeredSlides="true"
          :autoplay="{
            delay: 2000,
            disableOnInteraction: false,
          }"
          :modules="modules"
          class="mySwiper col-lg-7 col-12"
        >
          <swiper-slide v-for="banner in banners" :key="banner.id">
            <img
              v-if="idioma == '/es'"
              :src="publicPath + 'img/' + banner.imgSpanish"
              class="mx-auto w-100 h-100"
              alt="..."
            />

            <img
              v-else
              :src="publicPath + 'img/' + banner.img"
              class="mx-auto w-100 h-100"
              alt="..."
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>

  <router-view></router-view>

</template>

<script>
import DataView from "@/service/DataView";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { Autoplay } from "swiper";

export default {
  name: "BannerIndex",

  data() {
    return {
      publicPath: process.env.BASE_URL,
      arrayClassFloat: [
        "b-left",
        "b-right",
        "b-left",
        "b-right",
        "b-left",
        "b-right",
        "b-left",
        "b-right",
        "b-left",
        "b-right",
        "b-left",
        "b-right",
      ],
      banners: [],
      banner: [],
      services: [],
      service: [],
    };
  },

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    ArrayBannerIndex: {
      type: Array,
    },
    HrefBanner: {
      type: Array,
    },
    IconBannerIndex: {
      type: Array,
    },
    idioma: {
      type: String,
    },
  },

  setup() {
    return {
      modules: [Autoplay],
    };
  },

  mounted() {
    this.mostrarSlider();
  },

  methods: {

    async mostrarSlider() 
    {
      let es = this;
      let response = await DataView.selectVistas({
        opcion: 9,
        table: "banner",
      })
      
      es.banners = response.data;
    },
  },
};
</script>

<style lang="css" scoped>
#Banner {
  height: 100% !important;
}
.banner-service-items {
  z-index: 1;
}
.banner .b-left {
  float: left;
}
.banner .b-right {
  float: right;
}
div.banner-service-items > div:nth-child(2) > div:first-child,
div.banner-service-items > div:nth-child(1) > div:first-child {
  margin-top: 0px !important;
}
.banner .b-right .servicio-item {
  margin-left: auto !important;
}

.banner .slider,
.banner .swiper {
  height: 540px !important;
}
.banner {
  box-shadow: inset 0px 3px 6px 0px #00000029;
  background-color: #6466cb;
}
.banner .servicio-item {
  border: 1px solid #aeafe4;
  border-radius: 5px;
  height: 70px;
  align-items: center;
  display: flex;
  transition: 0.5s;
  z-index: 1;
  cursor: pointer;
}
.banner .servicio-item:hover {
  background: #001d7e;
  border-color: #001d7e;
}
.banner .servicio-item a {
  color: #fff !important;
  text-decoration: none;
}
.banner .servicio-item img {
  width: 45px;
}
.banner .servicio-item p {
  font-size: 12px;
}
.banner .banner-swiper {
  position: absolute;
  z-index: 0;
}
@media (max-width: 992px) {
  .banner .banner-swiper {
    position: relative !important;
  }

  .banner .slider,
  .banner .swiper,
  .banner .swiper-slide img {
    height: 600px !important;
  }
  .banner .banner {
    background-color: #6466cb;
  }

  .banner .servicio-item {
    height: 100%;
    margin: 15px 0px;
  }

  .banner .b-right,
  .banner .b-left {
    margin: 15px 0px;
    justify-content: center;
    float: none;
  }

  .banner .servicio-item img {
    height: 70px;
    width: auto;
  }

  .banner .servicio-item p {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .banner .slider,
  .banner .swiper,
  .banner .swiper-slide img {
    height: 400px !important;
  }
  .banner .servicio-item p {
    font-size: 10px;
  }
  .banner .servicio-item img {
    width: 55px;
    height: auto;
  }
}
</style>
