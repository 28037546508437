<template>
  <div id="marquee">
    <h3 class="news-text"><span class="me-5" v-for="n in 8" :key="n">{{ newsText }}</span></h3>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsText: 'Please Excuse Our Appareance This Website Is Under Construction',
    };
  }
}
</script>

<style scoped>
#marquee {
  position: sticky;
  top: 0;
  background: #111;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .161);
  color: #fff;
  font-family: montserrat-bold;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
  overflow: hidden;
  z-index: 1000;
}

.news-text {
  font-size: 28px;
  color: #ffffff;
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%;
  animation: scroll-left 60s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
