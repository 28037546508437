import Api from '@/service/Api'


const USER_API_URL = "/backend";

class UserDataService {
  menu(menu) {
    return Api().post(`${USER_API_URL}/MenuCrud.php`, menu);
  }
  services(service) {
    return Api().post(`${USER_API_URL}/ServicesCRUD.php`, service);
  }
  selectVistas(datos) {
    return Api().post(`${USER_API_URL}/selectComponent.php`, datos);
  }
  loginUsers(user) {
    return Api().post(`${USER_API_URL}/login.php`, user);
  }
  usuarios(user) {
    return Api().post(`${USER_API_URL}/userscrud.php`, user);
  }
  saveimg(form, header) {
    return Api().post(`${USER_API_URL}/guardarimg.php`, form, header);
  }
  pages(pages) {
    return Api().post(`${USER_API_URL}/pagescrud.php`, pages);
  }
  updatesite(site) {
    return Api().post(`${USER_API_URL}/updateSite.php`, site);
  }
  testimonials(testimonials) {
    return Api().post(`${USER_API_URL}/testimonialscrud.php`, testimonials);
  }
  banner(banner) {
    return Api().post(`${USER_API_URL}/bannercrud.php`, banner);
  }
  bannerservices(services) {
    return Api().post(`${USER_API_URL}/bannerservices.php`, services);
  }
  bannerSubservices(subservices) {
    return Api().post(`${USER_API_URL}/bannerSubServices.php`, subservices);
  }
  aboutcms(about) {
    return Api().post(`${USER_API_URL}/aboutcrud.php`, about);
  }
  blogcms(blog) {
    return Api().post(`${USER_API_URL}/blogcrud.php`, blog);
  }
  portfolio(portfolio) {
    return Api().post(`${USER_API_URL}/portfoliocrud.php`, portfolio);
  }
  packages(info) {
    return Api().post(`${USER_API_URL}/package.php`, info);
  }
  seo(seo) {
    return Api().post(`${USER_API_URL}/SeoCred.php`, seo);
  }
}

export default new UserDataService();
