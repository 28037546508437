<template>
  <div class="m-0 p-0 col-xl-10 col-lg-9 col-12 contenedor">
    <div
      class="header bg-primary py-3 px-4 justify-content-between justify-content-lg-end d-flex shadow-sm"
    >
      <button
        class="d-lg-none d-flex navbar-toggler navbar-dark"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="dropdown">
        <button
          class="btn btn-primary text-white p-1 dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Administrador
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li><a @click="logout" class="dropdown-item">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default 
{
  methods: {

    logout() 
    {
      localStorage.clear();
      this.$router.push("/login");
    },
    
  },
};
</script>

<style lang="scss" scoped></style>
