<template>
  <div class="textoSimpleIndex align-items-center col-12 d-flex flex-lg-row flex-column SeccionTextoIndex py-lg-5 py-4">
    <h1 class="d-lg-none d-block col-12 titulo text-center pb-lg-0 pb-4 text-dark text-fw-bold order-1">
      {{ TituloAbout }}
    </h1>

    <div class="col-lg-6 col-12 order-lg-2 order-3 px-sm-5 px-4">
      <h1 class="mb-4 d-lg-block d-none titulo text-dark text-fw-bold">
        {{ TituloAbout }}
      </h1>
      <div class="texto pt-lg-0 pt-5" v-html="parrafo1"></div>
      <div class="col-12 text-lg-end text-center mt-5">
        <button class="boton btn px-lg-3 px-4 py-lg-2 py-3" @click="goToLink">
          {{ textIndexBtn }}
        </button>
      </div>
    </div>

    <div class="order-lg-3 order-2 col-lg-6 col-12 justify-content-center d-flex text-center position-relative">

      <div class="container" id="h-video-content">
        <i class="fa fa-video text-warning" id="h-video-icon"></i>
        <video class="w-100 border shadow" :src="pathBackend + 'media/' + video" controls v-if="video != null"
          id="home-about-video"></video>
      </div>

    </div>
  </div>
</template>

<script>

import AboutService from "@/service/AboutService";
import global from '@/helpers/varGlobals'


export default
  {
    name: "SeccionTextoVueIndex",

    props:
    {
      TituloAbout: {
        type: String,
      },
      parrafo1: {
        type: String,
      },
      fondoBanner2: {
        type: String,
      },
      textIndexBtn: {
        type: String,
        default: "VIEW MORE",
      },
      buttonLink: {
        type: String,
        default: '#',
      },
    },

    data() {
      return {
        video: null,
        pathBackend: null
      }
    },
    created() {
      this.pathBackend = global.pathBackend
      this.getVideo()
    },

    methods: {
      async getVideo() {
        const resp = await AboutService.getVideo()
        this.video = resp.data.video
      },
      goToLink() {
        window.location.href = this.buttonLink;
      },
    }
  }
</script>

<style lang="css" scoped>
/* video */

#h-video-content {
  position: relative;
}

#h-video-icon {
  z-index: 1;
  position: absolute;
  font-size: 24px;
  left: 30px;
  top: 10px;
}

@media(max-width: 425px) {

  #h-video-icon {
    font-size: 16px;
    left: 30px;
    top: 0px;
  }

}


.SeccionTextoIndex .texto {
  font-size: 17px;
}

.SeccionTextoIndex .boton {
  font-size: 15px;
  background: transparent;
  border: 1px solid #222122;
  color: #222122;
  transition: 0.5s;
}

.SeccionTextoIndex .boton:hover {
  background: #222122;
  color: white;
}

.SeccionTextoIndex .linea {
  height: 1.5px;
  background: white;
  position: absolute;
  top: 85%;
}

.SeccionTextoIndex .logo-play {
  top: 40%;
  width: 100px;
}

.SeccionTextoIndex .fondoBanner2 {
  height: 550px;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: v-bind(fondoBanner2);
}

@media (max-width: 1200px) {
  .SeccionTextoIndex .fondoBanner2 {
    height: 600px;
  }
}

@media (max-width: 992px) {

  /* .SeccionTextoIndex .fondoBanner2 {
    height: 750px;
  } */
  .SeccionTextoIndex .texto {
    font-size: 1.3rem;
  }

  .SeccionTextoIndex .boton {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .SeccionTextoIndex .fondoBanner2 {
    height: 450px;
  }

  .SeccionTextoIndex .texto {
    font-size: 1rem;
  }

  .SeccionTextoIndex .boton {
    font-size: 0.8rem;
  }
}
</style>
