import Api from '@/service/Api'


const USER_API_URL = "/backend";


class TestimonialService {

  get() {
    return Api().get(`${USER_API_URL}/testimonial/get.php`)
  }

  create(data) {
    return Api().post(`${USER_API_URL}/testimonial/create.php`, data)
  }

  createTestimonial(data) {
    return Api().post(`${USER_API_URL}/testimonial/create-testimonial.php`, data)
  }

  getByToken(data) {
    return Api().post(`${USER_API_URL}/testimonial/get-company.php`, data)
  }

  delete(id) {
    return Api().get(`${USER_API_URL}/testimonial/delete.php?id=${id}`)
  }

  edit(id) {
    return Api().get(`${USER_API_URL}/testimonial/edit.php?id=${id}`)
  }

  getInfo(token) {
    return Api().get(`${USER_API_URL}/testimonial/get-info.php?token=${token}`)
  }

  testify(data) {
    return Api().post(`${USER_API_URL}/testimonial/testify.php`, data)
  }

  update(data) {
    return Api().post(`${USER_API_URL}/testimonial/update.php`, data)
  }

  getActives() {
    return Api().get(`${USER_API_URL}/testimonial/get-actives.php`)
  }

  getAvatars(gender) {
    return Api().get(`${USER_API_URL}/testimonial/get-avatars.php?gender=${gender}`)
  }

}

export default new TestimonialService();
