<template>
  <div class="footer bg-primary mt-1">
    <div class="footer-container col-10 mx-auto">
      <div class="footer-up d-flex flex-lg-row flex-column align-items-center pt-4 px-4">
        <div class="logo col-lg-3 col-md-8 col-9 pt-lg-0 pt-3">
          <img src="@/assets/src/img/webp/LogoPSOFNTECHWHITE.webp" class="w-100 h-auto" />
        </div>
        <div
          class="footer-menu d-flex flex-md-row flex-column col-lg-6 col-12 py-md-5 my-4 my-0 text-center justify-content-between mx-auto">
          <router-link :to="Home"
            class="text-white text-decoration-none py-lg-0 fw-semibold py-2 pb-md-0 pb-4">{{ TitleMenuFooter[0] }}</router-link>
          <router-link :to="About"
            class="text-white text-decoration-none py-lg-0 fw-semibold py-2 pb-md-0 pb-4">{{ TitleMenuFooter[1] }}</router-link>
          <a href="#"
            class="text-white text-decoration-none py-lg-0 fw-regular py-2 pb-md-0 pb-4">{{ TitleMenuFooter[2] }}</a>
          <router-link :to="Blog"
            class="text-white text-decoration-none py-lg-0 fw-regular py-2 pb-md-0 pb-4">{{ TitleMenuFooter[3] }}</router-link>
        </div>
        <div class="footer-social d-flex col-lg-auto col-md-3 col-5 mx-auto justify-content-center">
          <a :href="social.facebook" target="_blank" class="col-6">
            <img src="../assets/src/img/icon/facebook.svg" />
          </a>
          <a :href="social.instagram" target="_blank" class="col-6">
            <img src="../assets/src/img/icon/Instagram.svg" /></a>
        </div>
      </div>
      <div class="footer-down pt-md-5 my-md-3 pb-md-3 pt-0 my-0 pb-0">
        <div
          class="footer-down-container my-5 col-lg-9 col-md-11 col-12 mx-auto bg-white d-flex flex-lg-row flex-column align-items-center justify-content-between p-lg-3 p-md-5 p-4">
          <div class="footer-titulo text-primary col-lg-3 col-12 pb-lg-0 pb-md-4 pb-3 text-center">
            <h1 class="text-fw-bold">{{ TituloFindUs }}</h1>
          </div>
          <div class="footer-address-1 d-flex col-lg-4 mb-lg-0 mb-3">
            <router-link to="/login" class="footer-address-icon">
              <img src="../assets/src/img/icon/location.svg" />
            </router-link>
            <div class="footer-text text-black ps-2 fw-semibold">
              <!-- {{ adres.adress1 }} -->
              BROOKLYN (WILLIAMSBURG) <br>
              687 BROADWAY #6 <br>
              BROOKLYN, NY, 11206
            </div>
          </div>
          <div class="footer-address-2 d-flex col-lg-4 mb-lg-0 mb-3">
            <div class="footer-address-icon">
              <img src="../assets/src/img/icon/location.svg" />
            </div>
            <div class="footer-text text-black ps-2 fw-semibold">
              <!-- {{ adres.adress2 }} -->
              MANHATTAN (FINANCIAL DISTRICT) <br>
              88 NASSAU ST 4TH FL <br>
              NEW YORK, NY, 10038
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-desing bg-dark text-center py-3">
      <p class="fw-medium m-0 text-white">
        {{ TitleCopy }}
      </p>
    </div>
  </div>
</template>

<script>
import DataView from "@/service/DataView";

export default {
  name: "PiedePagina",
  data() {
    return {
      socials: [],
      social: [],
      adress: [],
      adres: [],
    };
  },
  props: {
    Home: {
      type: String,
    },
    About: {
      type: String,
    },
    TitleMenuFooter: {
      type: Array,
    },
    Blog: {
      type: String,
    },
    TituloFindUs: {
      type: String,
    },
    TitleCopy: {
      type: String,
    },
  },
  mounted() {
    this.mostrarSocial()
    this.mostrarAdress()
  },

  methods: {

    async mostrarSocial() {
      let es = this;
      let response = await DataView.selectVistas({
        opcion: 6,
        table: "business",
      })

      es.socials = response.data;
      for (es.social of es.socials) {
        return es.social;
      }
    },


    async mostrarAdress() {
      let es = this;
      let response = await DataView.selectVistas({
        opcion: 10,
        table: "site",
      })

      es.adress = response.data;
      for (es.adres of es.adress) {
        return es.adres;
      }

    },
  },
};
</script>

<style lang="css" scoped>
.footer .footer-menu {
  font-size: 16px;
}

.footer .footer-social img {
  width: 85% !important;
}

.footer .footer-text,
.footer .footer-desing {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.footer-down-container {
  border-radius: 53rem;
}

@media (max-width: 992px) {
  .footer-menu {
    font-size: 25px;
  }

  .footer .footer-social img {
    width: 80% !important;
  }

  .footer .footer-text,
  .footer .footer-desing {
    font-size: 22px;
    font-weight: 600;
  }

  .footer-down-container {
    border-radius: 3rem;
  }

  .footer-titulo h1 {
    font-size: 40px !important;
  }

  .footer-desing {
    text-transform: capitalize !important;
  }
}

@media (max-width: 768px) {
  .footer .footer-text {
    font-size: 12px;
    font-weight: 600;
  }

  .footer .footer-social img {
    width: 70% !important;
  }

  .footer .footer-desing {
    font-size: 12px;
    font-weight: 600;
  }
}

@media (max-width: 500px) {
  .footer-menu {
    font-size: 20px !important;
  }
}
</style>
