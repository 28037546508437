<template>
  <div id="Servicios" class="mb-4 d-flex justify-content-center servicios" :style="[ImgWeb, ImgResponsive]">
    <div class="">
      titulos aqui
    </div>
    <div class="servicios-container col-lg-6 col-12 ms-lg-auto ms-0 mx-lg-0 mx-auto">
      <div class="px-lg-0 px-sm-5 px-4 py-5 overflow-hidden">
        <div class="row m-0 p-0 flex-nowrap overflow-auto justify-content-between me-lg-5 me-0">
          <router-link :to="HrefServicios[0] + idioma" class="circulo d-flex flex-column align-items-center">
            <div class="contenedor-img d-flex align-items-end justify-content-center">
              <img class="col-10 w-75 h-auto" :src="publicPath + 'img/' + IconSubServices[0]" alt="" />
            </div>
            <p class="m-0 text-circulo d-flex align-items-center">
              {{ TitleServiciosIndex[0] }}
            </p>
          </router-link>
          <router-link :to="HrefServicios[1] + idioma" class="circulo d-flex flex-column align-items-center">
            <div class="contenedor-img d-flex align-items-end justify-content-center">
              <img class="col-10 w-75 h-auto" :src="publicPath + 'img/' + IconSubServices[1]" alt="" />
            </div>
            <p class="m-0 text-circulo text-center px-4 d-flex align-items-center">
              {{ TitleServiciosIndex[1] }}
            </p>
          </router-link>
          <router-link :to="HrefServicios[2] + idioma" class="circulo d-flex flex-column align-items-center">
            <div class="contenedor-img d-flex align-items-end justify-content-center">
              <img class="col-10 w-75 h-auto" :src="publicPath + 'img/' + IconSubServices[2]" alt="" />
            </div>
            <p class="m-0 text-circulo d-flex align-items-center">
              {{ TitleServiciosIndex[2] }}
            </p>
          </router-link>
          <router-link :to="HrefServicios[3] + idioma" class="circulo d-flex flex-column align-items-center">
            <div class="contenedor-img d-flex align-items-end justify-content-center">
              <img class="col-10 w-75 h-auto" :src="publicPath + 'img/' + IconSubServices[3]" alt="" />
            </div>
            <p class="m-0 text-circulo text-center px-4 d-flex align-items-center">
              {{ TitleServiciosIndex[3] }}
            </p>
          </router-link>
        </div>
        <div class="row m-0 p-0 flex-nowrap overflow-auto justify-content-between mt-5 me-lg-5 me-0">
          <router-link :to="HrefServicios[4] + idioma" class="circulo d-flex flex-column align-items-center">
            <div class="contenedor-img d-flex align-items-end justify-content-center">
              <img class="col-10 w-75 h-auto" :src="publicPath + 'img/' + IconSubServices[4]" alt="" />
            </div>
            <p class="px-3 text-center m-0 text-circulo d-flex align-items-center">
              {{ TitleServiciosIndex[4] }}
            </p>
          </router-link>
          <router-link :to="HrefServicios[5] + idioma" class="circulo d-flex flex-column align-items-center">
            <div class="contenedor-img d-flex align-items-end justify-content-center">
              <img class="col-10 w-75 h-auto" :src="publicPath + 'img/' + IconSubServices[5]" alt="" />
            </div>
            <p class="m-0 text-circulo text-center px-3 d-flex align-items-center">
              {{ TitleServiciosIndex[5] }}
            </p>
          </router-link>
          <router-link :to="HrefServicios[6] + idioma" class="circulo d-flex flex-column align-items-center">
            <div class="contenedor-img d-flex align-items-end justify-content-center">
              <img class="col-10 w-75 h-auto" :src="publicPath + 'img/' + IconSubServices[6]" alt="" />
            </div>
            <p class="m-0 text-circulo d-flex align-items-center">
              {{ TitleServiciosIndex[6] }}
            </p>
          </router-link>
          <router-link :to="HrefServicios[7] + idioma" class="circulo d-flex flex-column align-items-center">
            <div class="contenedor-img d-flex align-items-end justify-content-center">
              <img class="col-10 w-75 h-auto" :src="publicPath + 'img/' + IconSubServices[7]" alt="" />
            </div>
            <p class="m-0 text-circulo text-center px-4 d-flex align-items-center">
              {{ TitleServiciosIndex[7] }}
            </p>
          </router-link>
          <router-view></router-view>
        </div>
      </div>
      <div class="text-white pb-5 text-center fw-regular fs-5 d-lg-flex d-none">
        <h2 class="mx-auto">{{ TextServicioIndex }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: " ServiciosIndex",
  props: {
    TextServicioIndex: {
      type: String,
    },
    TitleServiciosIndex: {
      type: Array,
    },
    IconSubServices: {
      type: Array,
    },
    HrefServicios: {
      type: Array,
    },
    idioma: {
      type: String,
    },
    ImgWeb: {
      type: String,
    },
    ImgResponsive: {
      type: String,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="css" scoped>
/* width */
.servicios ::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.servicios ::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
}

/* Handle */
.servicios ::-webkit-scrollbar-thumb {
  background: #888484;
  border-radius: 50px;
}

.servicios .circulo {
  width: 155px;
  height: 155px;
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  font-weight: 800;
  font-size: 0.8rem;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
}

.servicios .contenedor-img {
  height: 85px;
}

.servicios .text-circulo {
  height: 70px;
}

.servicios .circulo:hover {
  background: white;
  color: #001d7e;
}

.servicios .circulo:hover img {
  filter: invert(85%) sepia(67%) saturate(2000%) hue-rotate(219deg) brightness(85%) contrast(140%);
}

.servicios {
  background-image: v-bind(ImgWeb);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media (max-width: 1200px) {
  .servicios .circulo {
    width: 120px;
    height: 120px;
    font-size: 0.6rem;
  }

  .servicios .contenedor-img {
    height: 70px;
  }

  .servicios .text-circulo {
    height: 50px;
  }
}

@media (max-width: 992px) {
  .servicios .circulo {
    width: 160px;
    height: 160px;
    font-size: 0.8rem;
  }

  .servicios .contenedor-img {
    height: 80px;
  }

  .servicios .text-circulo {
    height: 75px;
  }

  .servicios {
    background-image: v-bind(ImgResponsive);
    background-position: top;
    background-size: cover;
  }

  .servicios-container {
    padding-top: 65%;
  }
}

@media (max-width: 767px) {
  .servicios .circulo {
    width: 190px;
    height: 190px;
    margin: 0 20px;
  }

  .servicios .contenedor-img {
    height: 100px;
  }

  .servicios .text-circulo {
    height: 80px;
  }
}

@media (max-width: 576px) {
  .servicios .circulo {
    width: 150px;
    height: 150px;
    margin: 0 5px;
    font-size: 0.7rem;
  }

  .servicios .contenedor-img {
    height: 80px;
  }

  .servicios .text-circulo {
    height: 65px;
  }
}
</style>
