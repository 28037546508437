<template>
  <div>

    <banner-index v-if="activateBanner" :idioma="idioma" :IconBannerIndex="IconBannerIndex" :HrefBanner="HrefBanner"
      :ArrayBannerIndex="ArrayBannerIndex" />

    <seccion-texto-index v-if="activateAbout" :parrafo1="About.content" :TituloAbout="About.title"
      :fondoBanner2="fondoBanner2" :buttonLink="buttonLink" />

    <servicios-index v-if="activateServices1 && activateServices2" :idioma="idioma"
      :TextServicioIndex="TextServicioIndex" :TitleServiciosIndex="TitleServiciosIndex"
      :IconSubServices="IconSubServices" :HrefServicios="HrefServicios" :ImgResponsive="ImgResponsive"
      :ImgWeb="ImgWeb" />

  </div>

</template>

<script>

import { ref, computed, watchEffect } from 'vue'
import { useHead } from '@vueuse/head'

import DataView from "@/service/DataView";
import ServiciosIndex from "@/components/ServiciosIndex.vue";
import SeccionTextoIndex from "@/components/SeccionTextoIndex.vue";
import BannerIndex from "@/components/BannerIndex.vue";

export default {
  name: "App",

  components: {
    ServiciosIndex,
    BannerIndex,
    SeccionTextoIndex
  },

  data() {
    return {
      // img seccion subservicios
      ImgResponsive: "",
      ImgWeb: "",
      page: [],
      pages: [],
      // pages
      idioma: "/en",
      publicPath: process.env.BASE_URL,

      /*Servicios */
      TextServicioIndex: "And More...",
      IconSubServices: [],
      TitleServiciosIndex: [],
      HrefServicios: [],
      Subservicio: [],
      Subservicios: [],
      activateServices1: false,
      activateServices2: false,

      /*Banner */
      HrefBanner: [],
      ArrayBannerIndex: [],
      IconBannerIndex: [],
      ServiceTitleArrays: [],
      ServiceTitleArray: [],
      activateBanner: false,

      /*About Us */
      AnchodePagina: "",
      ordermd: "order-2 , pt-5",
      About: [],
      AboutUs: [],
      btnAbout: true,
      fondoBanner2: "",
      activateAbout: false,
      buttonLink: '/psofwebdevjsol/about/en'
    };
  },

  setup() {
    let title = ref("");
    let descipcionMeta = ref("");
    useHead({
      title: computed(() => title.value),
      meta: [
        {
          name: `title`,
          content: computed(() => title.value)
        },
        {
          name: `description`,
          content: computed(() => descipcionMeta.value),
        },
        {
          property: "og:title",
          content: computed(() => title.value)
        },
        {
          property: "og:description",
          content: computed(() => descipcionMeta.value),
        },
      ]
    })
    return { title, descipcionMeta }
  },

  mounted() {
    this.mostrarSEO()
    this.mostrarServices()
    this.mostrarAbout()
    this.mostrarSubServices()
    this.mostrarImg()
  },

  methods: {

    async mostrarServices() {
      let data = {
        opcion: 3,
        id: "banner"
      }
      const resp = await DataView.multiselect(data)
      this.ServiceTitleArrays = resp.data;
      for (this.ServiceTitleArray of this.ServiceTitleArrays) {
        this.ArrayBannerIndex.push(this.ServiceTitleArray.nameservice)
        this.HrefBanner.push(this.ServiceTitleArray.rout)
        this.IconBannerIndex.push(this.ServiceTitleArray.icono)
      }
      this.activateBanner = true
    },

    async mostrarSEO() {
      let data = {
        opcion: 1,
        table: 'seooriginal WHERE id = 1'
      }
      const resp = await DataView.selectVistas(data)
      this.seos = resp.data
      this.title = { value: '' }
      this.descipcionMeta = { value: '' }
      for (this.seo of this.seos) {
        this.title = this.seo.title
        this.descipcionMeta = this.seo.descripcion
      }
    },

    async mostrarAbout() {
      let data = {
        opcion: 7,
        table: "business"
      }
      const resp = await DataView.selectVistas(data)
      this.AboutUs = resp.data
      for (this.About of this.AboutUs) {
        this.fondoBanner2 = "url('" + this.publicPath + "img/" + this.About.img + "')"
        // return this.About
      }
      this.activateAbout = true
    },

    async mostrarSubServices() {
      let data = {
        opcion: 5,
        id: "parallax"
      }
      const resp = await DataView.multiselect(data)
      this.Subservicios = resp.data
      for (this.Subservicio of this.Subservicios) {
        this.TitleServiciosIndex.push(this.Subservicio.nameservice);
        this.HrefServicios.push(this.Subservicio.rout);
        this.IconSubServices.push(this.Subservicio.icono);
      }
      this.activateServices1 = true
    },

    async mostrarImg() {
      let data = {
        opcion: 8,
        table: "bannersubservice"
      }
      const resp = await DataView.selectVistas(data)
      this.pages = resp.data;
      for (this.page of this.pages) {
        this.ImgWeb = "url('" + this.publicPath + "img/" + this.page.imgDesktop + "')";
        this.ImgResponsive = "url('" + this.publicPath + "img/" + this.page.imgPhone + "')";
      }
      this.activateServices2 = true
    }
  },
};
</script>
