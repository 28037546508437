<template>
  <div>
    <news-banner v-if="showBanner"></news-banner>
    <component :is="layout" />
    <router-view v-if="!layout"></router-view>
  </div>
</template>

<script>
import LayoutEn from "@/Layouts/LayoutEn.vue";
import LayoutEs from "@/Layouts/LayoutEs.vue";
import LoginLayout from "@/Layouts/LoginLayout.vue";
import DashboardView from "@/Layouts/DashboardView.vue";
import NewsBanner from "@/components/NewsBanner.vue";

export default {
  name: "App",

  components: {
    LayoutEn,
    LayoutEs,
    DashboardView,
    LoginLayout,
    NewsBanner
  },

  data() {
    return {
      layout: null,
    };
  },

  computed: {
    showBanner() {
      return this.$route.path !== '/login' &&
        !this.$route.path.includes('/users') &&
        !this.$route.path.includes('/servicescms') &&
        !this.$route.path.includes('/site') &&
        !this.$route.path.includes('/pages') &&
        !this.$route.path.includes('/testimonials') &&
        !this.$route.path.includes('/testimonial-edit') &&
        !this.$route.path.includes('/aboutCMS') &&
        !this.$route.path.includes('/blogCMS') &&
        !this.$route.path.includes('/menu');
    },
  },

  watch: {
    $route(to) {
      this.layout = to.meta.layout

      if (this.layout == undefined)
        this.layout = "LayoutEn"
    }
  }
};
</script>
