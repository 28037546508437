<template>
  <div id="Testimonial" class="testimonial text-center my-5 py-5">
    <h1 class="text-white text-fw-bold pt-3">{{ TitleTestimonials }}</h1>

    <swiper :modules="modules" :slidesPerView="3" :spaceBetween="20" :breakpoints="{
      '0': {
        slidesPerView: 1,
      },
      '767': {
        spaceBetween: 1,
        spaceBetween: 20,
      },
      '992': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '1000': {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    }" :pagination="{
      clickable: true,
      bulletActiveClass: 'Active',
      bulletClass: 'swiper-pagination-bullet',
    }" class="mySwiper container-testimonial col-lg-10 col-md-10 col-sm-8 col-10 mx-auto pt-5">

      <swiper-slide v-for="page in pages" :key="page.id" class="mb-4 d-flex flex-lg-row flex-column">

        <div class="mx-auto col-12 mb-md-5 mb-4">
          <div class="shadow-sm bg-white rounded d-flex">
            <div class="col-7 py-4 px-2 align-self-center">
              <p class="text-secondary text-company text-fw-bold">
                {{ page.product }}
              </p>
              <i>
                <p class="card-texto mb-1 text-black small fw-medium lh-sm" v-html="page.content"></p>
              </i>
              <div class="col-12 justify-content-center text-secondary small d-flex">
                <p class="small">
                  {{ page.country }} {{ page.state }} <span class="mx-1 fs-6">|</span>
                  {{ dateFormat(page.date) }}
                </p>
              </div>
            </div>
            <div class="col-5 pt-lg-2 pt-3 rounded shadow">
              <div class="card-image mx-auto col-lg-10 col-md-8 col-sm-9 col-10">
                <div class="rating">
                  <star-rating :active-color="'#FFFF3D'" class="justify-content-center m-0 p-0 mx-auto mt-1 ms-2"
                    :padding="5.0" v-model:rating="page.rate" :read-only="true" :star-size="10" :show-rating="false" />
                </div>
                <div>
                  <!-- Avatar -->
                  <img :src="backend + 'img/avatars/' + page.img" class="mx-auto w-75 h-auto">
                </div>

                <p class="text-name text-black text-fw-bold m-0 p-0">
                  {{ page.name }}
                </p>
                <p class="text-secondary text-company text-fw-bold">
                  {{ page.company }}
                </p>
                <p class="text-company text-fw-bold text-secondary small">
                  {{ page.category }}
                </p>
                <p class="text-company text-fw-bold text-secondary small">
                  {{ page.position }}
                </p>
              </div>
            </div>
          </div>
        </div>

      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import DataView from "@/service/DataView";
import "swiper/css";
import "swiper/css/pagination";
import StarRating from "vue-star-rating";

import TestimonialService from "@/service/TestimonialService";


export default
  {
    name: "TestimoniosUsuarios",

    components: {
      Swiper,
      SwiperSlide,
      StarRating,
    },

    props: {
      TitleTestimonials: {
        type: String,
      },
    },

    setup() {
      return {
        modules: [Pagination],
      };
    },

    created() {
      this.show();
    },

    data() {
      return {
        pages: [],
        publicPath: process.env.BASE_URL,
        rating: 5
      }
    },

    methods: {

      async show() {
        const resp = await TestimonialService.getActives()
        this.pages = resp.data
      },

      dateFormat(date) {
        let d = new Date(date)

        let month = (d.getMonth() + 1).toString().padStart(2, '0')
        let day = d.getDate().toString().padStart(2, '0')
        let year = d.getFullYear()

        return month + '/' + day + '/' + year
      }

    }
  }

</script>


<style scoped>
/* width */
#Testimonial ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#Testimonial ::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
#Testimonial ::-webkit-scrollbar-thumb {
  background: #888484;
  border-radius: 50px;
}

#Testimonial {
  background: #6466cb;
}

#Testimonial .card-texto {
  font-size: 0.8rem;
  height: 110px;
  overflow: auto;
}

#Testimonial .text-company {
  font-size: 0.55rem;
}

#Testimonial .text-company.small {
  font-size: 0.4rem;
}

#Testimonial .text-name {
  font-size: 0.9rem;
}

#Testimonial .rating {
  background-image: url(@/assets/src/img/icon/GloboStarReview.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 20%;
}

#Testimonial .card-image {
  height: 220px;
  background-image: url(@/assets/src/img/png/fondoMorado.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

@media (max-width: 575px) {
  #Testimonial .card-texto {
    font-size: 0.7rem;
  }

  #Testimonial .text-company {
    font-size: 0.45rem;
  }

  #Testimonial .text-company.small {
    font-size: 0.3rem;
  }

  #Testimonial .text-name {
    font-size: 0.8rem;
  }

  #Testimonial .card-texto {
    height: 80px;
  }
}

@media (max-width: 400px) {
  #Testimonial .card-image {
    height: 180px;
  }

  #Testimonial .card-texto {
    height: 100px;
  }
}
</style>
<style lang="css">
#Testimonial .swiper-pagination-bullet {
  padding: 7px !important;
  background: white !important;
  opacity: 0.8;
}

#Testimonial .Active {
  background: #001d7e !important;
  opacity: 1;
}
</style>
