import axios from 'axios'


const baseURL = (process.env.NODE_ENV === 'production') ? 'https://psofntech.com/' : 'https://dev.psofntech.com/psofwebdevjosu0524'

export default () => {
  return axios.create({

    baseURL: baseURL,

    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
}