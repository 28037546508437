import Api from '@/service/Api'


const USER_API_URL = "/backend";


class AboutService 
{

  getVideo() {
    return Api().get(`${USER_API_URL}/about/get-video.php`)
  }
  
}

export default new AboutService();
