<template>
  <div id="DashboardView">
    <dash-menu></dash-menu>
    <div class="d-flex justify-content-end">
      <banner-admin></banner-admin>
    </div>
    <div class="d-flex justify-content-end">
      <bread-crumb
        :BreadCrum="BreadCrum"
        :TitleBreadCrumb="TitleBreadCrumb"
      ></bread-crumb>
    </div>
    <div class="d-flex justify-content-end contenido">
      <div class="col-xl-10 col-lg-9 col-12 px-md-5 px-sm-3 px-0 py-md-4 py-3">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import DashMenu from "@/components/CMS/DashMenu.vue";
import BannerAdmin from "@/components/CMS/BannerAdmin.vue";
import BreadCrumb from "@/components/CMS/BreadCrumb.vue";

export default 
{
  data() {
    return {
      TitleBreadCrumb: this.$route.name,
      BreadCrum: this.$route.path,
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      warningTimer: null,
      logoutTimer: null,
    };
  },

  components: {
    DashMenu,
    BannerAdmin,
    BreadCrumb,
  },

  mounted() 
  {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimer();
  },

  beforeCreate() 
  {
    let users = localStorage.getItem("user-info");
    if (!users) 
      this.$router.push("/");
  },

  watch: {
    $route() {
      this.TitleBreadCrumb = this.$route.name;
      this.BreadCrum = this.$route.path;
    },
  },


  methods: {

    setTimer() {
      this.logoutTimer = setTimeout(this.logoutUser, 6000000);
    },

    logoutUser() {
      localStorage.clear();
      this.$router.push("/login");
    },

    resetTimer() {
      clearTimeout(this.logoutTimer);
      this.setTimer();
    }

  },
};
</script>

<style>
body {
  background: rgb(241, 241, 241) !important;
  font-size: 14px !important;
}
.btn-info {
  background-color: #6466cb !important;
  border-color: #6466cb !important;
}
.contenido {
  height: 100% !important;
}
table.dataTable tbody th,
table.dataTable tbody td {
  border-bottom: none !important;
}
@media only screen and (max-width: 992px) {
  .navbar {
    top: -20px !important;
  }
}
</style>
