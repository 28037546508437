<template>
  <div id="LoginLayout">
    <router-link to="/login"></router-link>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>
