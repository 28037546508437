<template>
  <div
    class="col-xl-10 col-lg-9 col-12 px-4 py-3 d-flex shadow-sm justify-content-between bg-white"
  >
    <div class="d-flex align-items-center">
      <h6 class="m-0">{{ TitleBreadCrumb }}</h6>
    </div>
    <div
      class="d-flex small align-items-center text-truncate col-sm-auto col-4"
    >
      <i :class="['pe-2', 'small', 'fa-solid', 'fa-home']"></i>
      {{ BreadCrum }}
    </div>
  </div>
</template>

<script>

export default 
{
  props: {

    TitleBreadCrumb: {
      type: String,
      default: "Dashboard",
    },
    
    BreadCrum: {
      type: String,
      default: "Dashboard",
    },
  },
};
</script>

<style lang="scss" scoped></style>
