<template>
  <div>
    <nav class="navbar col-xl-2 col-lg-3 fixed-top align-items-start navbar-light navbar-expand-lg bg-white">
      <div class="m-0 p-0 container-fluid flex-column align-items-start">
        <div class="shadow m-0 p-0 offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel">
          <div class="offcanvas-header justify-content-end">
            <button ref="BtnCerrar" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="text-center">
            <img src="@/assets/src/img/webp/LogoPSOFNTECHORIGINAL.webp" class="offcanvas-title py-4 w-75" />
          </div>

          <div class="offcanvas-body overflow-auto d-block mt-5 px-3">
            <ul class="navbar-nav justify-content-end flex-grow-1 px-3 flex-column">
              <li class="nav-item py-2">
                <router-link class="nav-link" aria-current="page" to="/menu">
                  <i class="icon-menu fa-solid pe-2 fa-bars"></i>
                  Menu</router-link>
              </li>
              <li class="nav-item py-2">
                <router-link @click="close" to="/users" class="nav-link">
                  <i class="icon-menu fa-solid pe-2 fa-user-large"></i>
                  Users</router-link>
              </li>
              <li class="nav-item py-2 dropdown">
                <div :to="{ name: 'Site' }" :style="Cambio" :class="[
                  'nav-link dropdown-toggle',
                  { activeServicie: subIsActive('/site') },
                ]" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="icon-menu fa-solid pe-2 fa-earth-americas"></i>
                  Site
                  <i class="ps-2 small fa-solid fa-angle-down"></i>
                </div>
                <ul class="dropdown-menu border-0" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link @click="close" to="/site/general-configuration" class="dropdown-item" href="#">General
                      configuration</router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <router-link @click="close" to="/site/Contact" class="dropdown-item" href="#">Contact
                      information</router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <router-link @click="close" to="/site/Business" class="dropdown-item" href="#">
                      Business information</router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <router-link @click="close" to="/site/SEO" class="dropdown-item" href="#">SEO</router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <router-link @click="close" to="/site/Banner" class="dropdown-item" href="#">Banner</router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <router-link @click="close" to="/site/BannerSubServicios" class="dropdown-item"
                      href="#">Parallax</router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                </ul>
              </li>
              <li class="nav-item py-2">
                <router-link @click="close" class="nav-link" to="/pages">
                  <i class="icon-menu fa-solid pe-2 fa-clone"></i>
                  Pages
                </router-link>
              </li>
              <li class="nav-item dropdown py-2">
                <div :to="{ name: 'Services CMS' }" :style="CambioServices" :class="[
                  'nav-link dropdown-toggle',
                  { activeServicie: subIsActiveServices('/servicescms') },
                ]" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="icon-menu fa-solid pe-2 fa-box-open"></i>
                  Services
                  <i class="ps-2 small fa-solid fa-angle-down"></i>
                </div>
                <ul class="dropdown-menu border-0" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link @click="close" to="/servicescms/services" class="dropdown-item">All
                      Services</router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                  <li>
                    <router-link @click="close" to="/servicescms/Package" class="dropdown-item"
                      href="#">Package</router-link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>
                </ul>
              </li>
              <li class="nav-item py-2">
                <router-link @click="close" to="/testimonials" class="nav-link" href="#">
                  <i class="icon-menu fa-solid fa-comments pe-2"></i>
                  Testimonials
                </router-link>
              </li>
              <li class="nav-item py-2">
                <router-link @click="close" to="/aboutCMS" class="nav-link" href="#">
                  <i class="fa-solid fa-file-pen icon-menu pe-2"></i>
                  About Us
                </router-link>
              </li>
              <li class="nav-item py-2">
                <router-link @click="close" to="/blogCMS" class="nav-link" href="#">
                  <i class="fa-solid fa-file-pen icon-menu pe-2"></i>
                  Blog
                </router-link>
              </li>
              <li class="nav-item dropdown py-2">
                <div :to="{ name: 'Portafolios' }" :style="CambioPortfolio" :class="[
                  'nav-link dropdown-toggle',
                  { activePortfolio: subIsActivePortfolio('/portfolio') },
                ]" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="icon-menu fa-solid pe-2 fa-briefcase"></i>
                  Portfolios
                  <i class="ps-2 small fa-solid fa-angle-down"></i>
                </div>
                <ul class="dropdown-menu border-0" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link @click="close" to="/portfolio/web" class="dropdown-item" href="#">Web
                      Desing</router-link>
                  </li>
                  <li v-for="portafolio in portafolios" :key="portafolio">
                    <router-link @click="close" :to="'/portfolio/' + portafolio.name.split(' ').join('')"
                      class="dropdown-item" href="#">{{ portafolio.name }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import UserDataService from "@/service/UserDataService";

export default
  {
    data() {
      return {
        portafolio: [],
        portafolios: [],
        Cambio: {
          color: "",
          borderBottom: "",
        },
        CambioPortfolio: {
          color: "",
          borderBottom: "",
        },
        CambioServices: {
          color: "",
          borderBottom: "",
        },
        CambioPages: {
          color: "",
          borderBottom: "",
        },
      };
    },

    created() {
      this.selectPortfolio();
    },

    methods: {

      async selectPortfolio() {
        let es = this;

        const response = await UserDataService.selectVistas({
          opcion: 2,
          datos: "*",
          table: "services",
          idWhere: "parent_id",
          id: 19,
        })

        es.portafolios = response.data;
      },


      async subIsActive(input) {
        const paths = Array.isArray(input) ? input : [input];

        return paths.some((path) => {
          if (this.$route.path.indexOf(path) == 0) {
            this.$data.Cambio.borderBottom = "2px solid #001d7e";
            this.$data.Cambio.color = "#001d7e !important";
          }
          else {
            this.$data.Cambio.borderBottom = "none";
            this.$data.Cambio.color = "#222222db !important";
          }
        });
      },

      subIsActivePortfolio(input) {
        const paths = Array.isArray(input) ? input : [input];

        return paths.some((path) => {
          if (this.$route.path.indexOf(path) == 0) {
            this.$data.CambioPortfolio.borderBottom = "2px solid #001d7e";
            this.$data.CambioPortfolio.color = "#001d7e !important";
          }
          else {
            this.$data.CambioPortfolio.borderBottom = "none";
            this.$data.CambioPortfolio.color = "#222222db !important";
          }
        });
      },

      subIsActiveServices(input) {
        const paths = Array.isArray(input) ? input : [input];

        return paths.some((path) => {
          if (this.$route.path.indexOf(path) == 0) {
            this.$data.CambioServices.borderBottom = "2px solid #001d7e";
            this.$data.CambioServices.color = "#001d7e !important";
          }
          else {
            this.$data.CambioServices.borderBottom = "none";
            this.$data.CambioServices.color = "#222222db !important";
          }
        });
      },

      close() {
        if (window.innerWidth <= 992) {
          var objO = this.$refs.BtnCerrar;
          objO.click();
        }
      },
    },
  };

</script>

<style lang="css" scoped>
.offcanvas {
  height: 100vh;
}

.nav-link {
  color: #222222db !important;
}

.icon-menu,
.dropdown-menu li a {
  font-size: 13px;
}

.dropdown .show .fa-angle-down {
  transform: rotateX(180deg) !important;
}

.dropdown .fa-angle-down {
  transition: 0.5s !important;
}

.dropdown .router-link-exact-active {
  border-bottom: none !important;
}

.router-link-exact-active .icon-menu {
  color: #001d7e;
}

.router-link-exact-active {
  color: #001d7e !important;
  border-bottom: 2px solid #001d7e;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

@media only screen and (min-width: 992px) {
  .navbar-nav .dropdown-menu {
    position: initial !important;
  }
}
</style>
