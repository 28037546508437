<template>
  <div id="LayoutEs" class="Es">
    <menu-cabecera-es v-if="!$route.meta.menuEs" />
    <testimonios-usuarios-es
      v-if="!$route.meta.testimoniosusuaiosEs"
      :TitleTestimonials="'Testimonios'"
    />
    <contacto-form-es
      v-if="!$route.meta.contactformEs"
      :TituloContactano="'Contáctanos'"
      :ArrayContact="ArrayContact"
    />

    <piede-pagina-es
      v-if="!$route.meta.piepaginaEs"
      :Blog="'/Blog/es'"
      :Home="'/'"
      :TitleCopy="'Reservados todos los derechos. PSOFNTECH, INC 2021.'"
      :TitleMenuFooter="TitleMenuFooter"
      :TituloFindUs="'Ubícanos'"
    />
  </div>
</template>
<script>

/* Es */
import MenuCabeceraEs from "@/components/es/MenuCabeceraEs.vue";
import ContactoFormEs from "@/components/ContactoForm.vue";
import TestimoniosUsuariosEs from "@/components/TestimoniosUsuarios.vue";
import PiedePaginaEs from "@/components/PiedePagina.vue";
// seo
import { ref } from "vue";

import DataView from "@/service/DataView";
import { computed } from "vue";
import { useHead } from "@vueuse/head";

export default 
{
  name: "App",

  components: {
    /*Es*/
    MenuCabeceraEs,
    ContactoFormEs,
    TestimoniosUsuariosEs,
    PiedePaginaEs,
  },

  data() {
    return {
      TitleMenuFooter: ["INICIO", "NOSOTROS", "SERVICIOS", "BLOG"],
      ArrayContact: [" Nombre", "Apellido", "Correo", "Mensaje", "ENVIAR"],
      /*seo */
      seos: [],
      seo: [],
    };
  },

  created() {
    this.mostrarSEO();
  },

  setup() {
    let metakey = ref("");
    let descipcionMeta = ref("");
    useHead({
      meta: [
        {
          name: `keywords`,
          content: computed(() => metakey.value),
        },
        {
          name: `description`,
          content: computed(() => descipcionMeta.value),
        },
        {
          property: "og:description",
          content: computed(() => descipcionMeta.value),
        },
      ],
    });
    return { metakey, descipcionMeta };
  },

  methods: {

    async mostrarSEO() 
    {
      let es = this;
      
      const response = await DataView.selectVistas({
        opcion: 1,
        table: "seooriginal",
      })

      es.seos = response.data;
      for (es.seo of es.seos) 
      {
        es.metakey = es.seo.keywords;
        es.descipcionMeta = es.seo.descripcion;
      }
      
    },
  },
};
</script>

<style lang="css" scope="">
.ql-align-center {
  text-align: center !important;
}
.ql-align-left {
  text-align: left !important;
}
.ql-align-right {
  text-align: right !important;
}
.ql-align-justify {
  text-align: justify !important;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(@/assets/src/font/Montserrat-Regular.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-1";
  src: local("Montserrat-1"),
    url(@/assets/src/font/Montserrat-Bold.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat-2";
  src: local("Montserrat-2"),
    url(@/assets/src/font/Montserrat-SemiBold.ttf) format("truetype");
  font-display: swap;
}
p {
  margin-bottom: 0px !important;
}
.text-fw-bold {
  font-family: "Montserrat-1" !important;
}
.text-fw-light {
  font-family: "Montserrat" !important;
}
.fw-semibold {
  font-family: "Montserrat-2" !important;
}
.text-black,
.bg-dark {
  --bs-black-rgb: 34, 34, 34;
}

.bg-primary,
.btn-primary {
  --bs-primary-rgb: 0, 29, 126;
  background-color: rgb(0, 29, 126) !important;
  border-color: rgb(0, 29, 126) !important;
}

html,
body {
  font-family: "Montserrat" !important;
}

#LayoutEs,
#LayoutEn {
  background: white;
}

#Blog #seccionTextoOnly h1,
.textoSimpleIndex h1,
.testimonial h1,
.contact h1,
.cardPrecio h1,
.portafolioWeb h1,
.textoSimple h1,
.titu {
  font-size: 60px !important;
}

@media (max-width: 992px) {
  .navbar {
    position: inherit !important;
  }

  #Blog #seccionTextoOnly h1,
  .textoSimpleIndex h1,
  .titu .testimonial h1,
  .contact h1,
  .cardPrecio h1,
  .cardPrecio h1,
  .portafolioWeb h1,
  .textoSimple h1 {
    font-size: 60px !important;
  }
}
@media (max-width: 460px) {
  #Blog #seccionTextoOnly h1,
  .textoSimpleIndex h1,
  .testimonial h1,
  .titu,
  .contact h1,
  .cardPrecio h1,
  .cardPrecio h1,
  .portafolioWeb h1,
  .textoSimple h1 {
    font-size: 40px !important;
  }
}
</style>
