import Api from '@/service/Api'


const USER_API_URL = "/backendView";


class DataView 
{

  selectVistas(datos) 
  {
    if(datos.opcion == 1)
      return Api().get(`${USER_API_URL}/SelectData1.php?table=${datos.table}`)
    
    else if(datos.opcion == 2)
      return Api().get(`${USER_API_URL}/SelectData2.php?table=${datos.table}&id=${datos.id}`)
    
    else if(datos.opcion == 3)
      return Api().get(`${USER_API_URL}/SelectData3.php?table=${datos.table}&id=${datos.id}&idTable=${datos.idTable}`)
    
    else if(datos.opcion == 4)
      return Api().get(`${USER_API_URL}/SelectData4.php?id=${datos.id}`)

    else if(datos.opcion == 5)
      return Api().get(`${USER_API_URL}/SelectData5.php?table=${datos.table}`)

    else if(datos.opcion == 6)
      return Api().get(`${USER_API_URL}/SelectData6.php?table=${datos.table}`)

    else if(datos.opcion == 7)
      return Api().get(`${USER_API_URL}/SelectData7.php?table=${datos.table}`)

    else if(datos.opcion == 8)
      return Api().get(`${USER_API_URL}/SelectData8.php?table=${datos.table}`)

    else if(datos.opcion == 9)
      return Api().get(`${USER_API_URL}/SelectData9.php?table=${datos.table}`)

    else if(datos.opcion == 10)
      return Api().get(`${USER_API_URL}/SelectData10.php?table=${datos.table}`)
  }


  selectMenu(menu) 
  {
    if(menu.opcion == 1)
      return Api().get(`${USER_API_URL}/SelectMenu1.php?id=${menu.id}&idTable=${menu.idTable}`)

    else if(menu.opcion == 2)
      return Api().get(`${USER_API_URL}/SelectMenu2.php?id=${menu.id}`)

    else if(menu.opcion == 3)
      return Api().get(`${USER_API_URL}/SelectMenu3.php?table=${menu.table}&id=${menu.id}&idTable=${menu.idTable}`)
  }


  multiselect(datos) 
  {
    if(datos.opcion == 1)
      return Api().get(`${USER_API_URL}/SelectMultiTable1.php?id=${datos.id}`)

    else if(datos.opcion == 2)
      return Api().get(`${USER_API_URL}/SelectMultiTable2.php?table=${datos.table}&id=${datos.id}`)

    else if(datos.opcion == 3)
      return Api().get(`${USER_API_URL}/SelectMultiTable3.php?id=${datos.id}`)

    else if(datos.opcion == 4)
      return Api().get(`${USER_API_URL}/SelectMultiTable4.php?table=${datos.table}&id=${datos.id}`)

    else if(datos.opcion == 5)
      return Api().get(`${USER_API_URL}/SelectMultiTable5.php?id=${datos.id}`)
  }


  email() {
    return Api().get(`${USER_API_URL}/email.php`);
  }
  
}

export default new DataView();
